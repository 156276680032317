import React from "react"

import styles from "./styles.module.less"

import Slide from "react-reveal/Slide"
import Reveal from "react-reveal/Reveal"

const Header = () => (
  <div className={styles.Header}>
    <div className={styles.wrapText2}>
      <Slide bottom>
        <h1 className={styles.MakeRealWork}>
          Make real work
          <br />
          with real people.
        </h1>
      </Slide>
    </div>
    <div className={styles.flex}>
      <div className={styles.imgWrapper}>
        <video id="background-video" loop autoPlay muted>
          <source
            src="https://eroi-s3.s3.amazonaws.com/thesis/careers.mov"
            type="video/mp4"
          />
          <source
            src="https://eroi-s3.s3.amazonaws.com/thesis/careers.mov"
            type="video/ogg"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.wrapText}>
        <Reveal>
          <p className={styles.WereCollecting}>
            We’re collecting recipes for digital work that
            <br className={styles.mobilehide} /> matters to humans.
            <br />
            Know it?
          </p>
        </Reveal>
      </div>
    </div>
  </div>
)

export default Header
