import React, { Component } from "react"

import Intro from "./components/Intro"
import Middle from "./components/Middle"
import Bottom from "./components/Bottom"
import BottomBanner from "./components/BottomBanner"

export default class Home extends Component {
  render() {
    return (
      <div>
        <Intro />
        <Bottom />
        <BottomBanner />
      </div>
    )
  }
}
