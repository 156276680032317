import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import CareersBlock from "../components/CareersBlock"

const CareersPage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Work at Thesis | A digital agency"
      description="Interested in a digital marketing career? Want to work with real teams of real people in a real, inclusive environment? See the job openings at Thesis."
    />
    <CareersBlock />
  </Layout>
)

export default CareersPage
