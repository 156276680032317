import React, { Component } from "react"

import Header from "./components/Header"

import styles from "./styles.module.less"

export default class CareersBlock extends Component {
  render() {
    return (
      <section className={styles.Careers}>
        <div className={styles.Wrapper}>
          {" "}
          <Header />
        </div>
      </section>
    )
  }
}
