import React from "react"
import styles from "./styles.module.less"
import { Link } from "gatsby"
import emdash from "./img/emdash.png"

const Banner = props => {
  {
    /*const link = (
                <div className={styles.link}>
                  <Link to="/about">eROI is now Thesis&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8594;</Link>
                </div>
            )}*/
  }
  let bg = props.bannerBG ? styles.nobg : styles.background
  return (
    <section data-component="Banner" className={styles.Banner}>
      <Link to="/contact-us">
        <div className={bg}>
          <div className={styles.link}>
            <span>Don&apos;t see your dream job?</span>
            <br />
            <div className={styles.links}>
              <span>Let&apos;s talk</span>
              <img alt="" src={emdash} />
            </div>
          </div>
        </div>
      </Link>
    </section>
  )
}

export default Banner
