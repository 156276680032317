import React, { useEffect, useState } from "react"
import styles from "./styles.module.less"
import "./embed.less"

const bambooJobs = `https://thesis.bamboohr.com/jobs/embed2.php`

/**
 * Content is fetched from Bamboo using the URL above.
 * The URL returns an HTML snippit. Example markup beloow
 * 
 <div class="BambooHR-ATS-board">
    <h2>Open Positions</h2>
    <ul class="BambooHR-ATS-Department-List">
      <li
        id="bhrDepartmentID_18230"
        class="BambooHR-ATS-Department-Item"
      >
        <div
          id="department_18230"
          class="BambooHR-ATS-Department-Header"
        >
          Partner Services
        </div>
        <ul class="BambooHR-ATS-Jobs-List">
          <li id="bhrPositionID_80" class="BambooHR-ATS-Jobs-Item">
            <a href="//eroi.bamboohr.com/jobs/view.php?id=80">
              Account Manager – Channel Operations
            </a>
            <span class="BambooHR-ATS-Location">
              Beaverton, Oregon
            </span>
          </li>
        </ul>
      </li>
    </ul>
</div>
 */

const Bottom = () => {
  const [jobs, setJobs] = useState("")

  useEffect(() => {
    const getJobs = async () => {
      if (window || window.fetch) {
        try {
          let response = await fetch(bambooJobs)
          let text = await response.text()
          setJobs(text)
          Array.from(
            // open jobs in a new window
            document.querySelectorAll(".BambooHR-ATS-Jobs-List a")
          ).forEach(a => {
            a.setAttribute("target", "_blank")
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
    getJobs()
  })

  return (
    <>
      <div className={styles.divBar}>
        <section className={styles.Middle}>
          <div className={styles.wrapper}>
            <div className={styles.wrapText}>
              <h1 className={styles.header}>
                Current
                <br />
                Openings
              </h1>
            </div>
          </div>
        </section>
        <section className={styles.Bottom}>
          <div className={styles.wrapper}>
            <div
              className={styles.flex}
              dangerouslySetInnerHTML={{ __html: jobs }}
            />
            {!jobs && (
              <div className="BambooHR-ATS-Department-Header">LOADING…</div>
            )}
          </div>
        </section>
        <section>
          <div className={styles.block} />
        </section>
      </div>
    </>
  )
}

export default Bottom

//First two lines of job posting
